<template>
	<div class="courseInfo">
		<div class="info_Header">
			<div class="info_H_Con">
				<div class="crumbs">
					<span class="current">当前位置：</span>
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item><span class="cu" @click="toHome">首页</span></el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/courseList' }">课程列表</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div class="Header_Details">
				<img :src="courseDetail.cou_thumb" class="coursesImg" />
				<div class="Header_Right">
					<div class="Header_Tit">{{courseDetail.cou_name}}</div>
					<div class="Header_TY">
						<span class="Header_Type">{{course_type}}</span>
						<span class="Header_Year">【{{courseDetail.cou_year}}年】</span>
					</div>
					<div class="Header_DA">
						<span class="Header_duration">时长：{{courseDetail.cou_sumtime}}学时，共{{courseDetail.courseware_count}}节</span>
						<p class="Header_Audition" v-if="!isBuy" @click="gotoVideoPlay">
							<img src="@/views/images/play_icon.png" />
							<span>免费试听</span>
						</p>
					</div>
					<div class="Header_sign">
						<img src="@/views/images/time_icon.png" />
						<span>报名日期：{{con_sign_time}}</span>
						<!-- <span>报名日期：请耐心等候通知</span> -->
					</div>
					<div class="Header_train">
						<img src="@/views/images/time_icon.png" />
						<span>培训日期：{{con_exam_time}}</span>
						<!-- <span>培训日期：请耐心等候通知</span> -->
					</div>
					<div v-if="isBuy">
						<div class="Header_LH">
							<span class="Header_Learned" v-if="courseDetail.stti_effective_times">已学{{this.PublicJs.formatSeconds(courseDetail.stti_effective_times)}}</span>
							<span class="Header_Hour">正在学习：课时{{courseDetail.next_courseware_weight}}</span>
						</div>
						<p class="Header_Keep" @click="gotoVideoPlay">继续学习</p>
					</div>
					<div v-else>
						<div class="Header_Price">￥<span>{{courseDetail.cou_price}}</span></div>
						<p class="Header_Learn" :class="{ash:!canSignUp}" @click="learnNow">立即报名</p>
						<p class="Header_tocart" :class="{ash:!canSignUp}" v-loading.fullscreen.lock="loading" @click="addToCart">加入购物车</p>
					</div>
				</div>
			</div>			
		</div>
		<div class="courseCon">
			<div class="courseCon_L">
				<div class="courseIntro">
					<div class="Intro_tit">
						课程简介
					</div>
					<div class="Intro_des" :style="{'height':isOpen ? 'auto':'85px'}">
						<div ref="Intro_des" v-html="courseDetail.cou_description"></div>
					</div>
					<p class="moreIndustry" @click="openOrHide" v-if="moreShow"><span v-if="!isOpen">更多</span><i :class="isOpen?'el-icon-arrow-up':'el-icon-arrow-down'"></i></p>
				</div>
				<div class="courseMenu">
					<div class="courseMenu_tit" v-if="isBuy">
						<span :class="{active: MenuChose == 'ml'}" @click="tabChange('ml')">课程目录</span>
						<span :class="{active: MenuChose == 'bj'}" @click="tabChange('bj')">课程笔记</span>
					</div>
					<div class="courseMenu_tit" v-else>
						<span class="only">课程目录</span>
					</div>
					<div v-if="isBuy && MenuChose == 'bj'" class="bjList">
						<div class="NotesList">
							<div class="NotesItem" v-for="(item,index) in NotesList" :key="index">
								<div class="NotesItem_top">
									<img :src="item.get_student.stu_headimg" v-if="item.get_student.stu_headimg" class="headimg" />
									<span v-else>
										<img v-if="item.get_student.stu_sex == '女'" src="@/views/images/nv.png" alt="" class="headimg"  />
										<img v-else src="@/views/images/nan.png" alt="" class="headimg" />
									</span>
									<div class="NotesItem_info">
										<p class="NotesItem_name">{{item.get_student.stu_name}}</p>
										<p class="NotesItem_TW">
											<span class="NotesItem_time">{{item.createtime}}</span>
											<span class="NotesItem_weight" v-if="item.get_courseware">{{item.get_courseware.cow_weight}}课时</span>
										</p>
									</div>
								</div>
								<div class="NotesItem_con" v-html="html_decode(item.cono_notes)"></div>
							</div>
						</div>
						<div class="NotesPaginat" v-if="NotesList.length > 10">
							<el-pagination
							  background
							  layout="prev, pager, next"
							  :total="total"
							  :page-size="pageSize"
							  @current-change="changPage">
							</el-pagination>
						</div>
					</div>
					<div v-if="!isBuy || MenuChose == 'ml'" class="mlList">
						<div class="mlItem" v-for="(item,index) in mlList" :key="index" @click="gotoVideoPlays(item)">
							<span class="mlItem_no">课时{{item.key}}：</span>
							<span class="mlItem_tit" :title="item.cow_name">{{item.cow_name}}</span>
							<p class="mlItem_xs">
								<span v-if="item.cou_showrule == 1">{{item.cow_period_int}}学时</span>
								<span v-else>{{item.cow_duration}}</span>
								<i class="el-icon-video-play"></i>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="courseCon_R">
				<div class="lecturerInfo" v-if="lecturerList.length">
					<img src="@/views/images/lecturerbg.png" />
					<img :src="lecturerInfo.lec_picture" class="lecturerPicture"/>
					<p class="lec_name">{{lecturerInfo.lec_name}}</p>
					<div class="lec_evaluate">
						<div class="evaluateItem">
							<div class="evaluate_num">
								<el-rate
								  v-model="lecturerInfo.lec_praise"
								  disabled
								  text-color="#ff9900"
								  >
								 </el-rate>
							</div>
							<p class="evaluate_text">好评度</p>
						</div>
						<div class="evaluateItem">
							<p class="evaluate_num">{{lecturerInfo.lec_course}}</p>
							<p class="evaluate_text" style="font-size: 12px;">课程数</p>
						</div>
						<!-- <div class="evaluateItem">
							<p class="evaluate_num">{{lecturerInfo.lec_study}}</p>
							<p class="evaluate_text">学习人数</p>
						</div> -->
					</div>
					<div class="lec_des" :style="{'height':isOpendes ? 'auto':'82px'}">
						<div ref="lec_des">{{lecturerInfo.lec_description}}</div>
					</div>
					<p class="morelec_des" @click="openOrHidedes" v-if="moreShowdes"><i :class="isOpendes?'el-icon-arrow-up':'el-icon-arrow-down'"></i></p>
					<i class="el-icon-arrow-left lec_prev" @click="lec_prev" v-if="lecturerList.length > 1"></i>
					<i class="el-icon-arrow-right lec_next" @click="lec_next" v-if="lecturerList.length > 1"></i>
				</div>
				<div class="relevantCourse">
					<div class="relevant_tit">相关课程</div>
					<div class="relevantList">
						<div v-for="(item,index) in relevantCourse" :key="index" class="relevantItem" @click="toCourseInfo(item)">
							<img :src="item.cou_thumb" class="relevant_img" v-if="item.cou_thumb" />
							<img src="@/views/images/coursesImg.png" class="relevant_img" v-else />
							<div class="relevant_Con">
								<p class="relevant_Name">{{item.cou_name}}</p>
								<p class="relevant_Year">{{item.cou_year}}年{{item.course_type.dic_name}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';  
	import { Loading, Rate} from 'element-ui';
	export default {
	    data() {
			return {
				loading:false,
				isBuy:false,//是否购买
				courseDetail:{},//课程详情
				con_sign_time:"",//报名时间
				con_exam_time:"",//培训时间
				isOpen:false,//是否展开简介
				moreShow:false,//是否显示更多
				MenuChose:"ml",//课程目录或者课程笔记
				course_type:"",//课程类别
				mlList:[],//课程目录列表
				lecturerList:[],//讲师列表
				lecturerInfo:{},//当前讲师信息
				isOpendes:false,//是否展开讲师简介
				moreShowdes:false,//是否显示讲师简介更多
				cuurlecturer:0,//当前第几个讲师
				relevantCourse:[],//相关课程列表
				pageSize:10,//每页条数
				NotesList:[],//笔记列表
				total:0,//笔记总数
				canSignUp:false,//是否在报名范围内
				con_sign_time_arr:[],//报名时间
				isOrderStatus:0,//是否在订单中
				isCarts:0,//是否在购物车中
				are_code:"",//城市code
			}
	    },
		methods:{
			//跳转首页
			toHome(){
				this.$router.push({
					path: "/"
				});
				Bus.$emit('busIsHome', true);//是否为省平台
				Bus.$emit('busmenuChose', "");
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//获取课程详情
			getCourseDetail(){
				var that = this
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/courseDetail.html", {
					course_id:this.$route.query.course_id,
					token:window.sessionStorage.getItem('token'),
					are_code:this.are_code
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.courseDetail = response.data.data.data;
						this.isBuy = response.data.data.isBuy;
						this.course_type = this.courseDetail.course_type.dic_name
						if(this.courseDetail.config_info.con_sign_time){
							this.con_sign_time_arr = this.courseDetail.config_info.con_sign_time.split(",")
							this.con_sign_time = this.con_sign_time_arr[0] + " 至 " + this.con_sign_time_arr[1];
							this.canSignUp = this.PublicJs.isDuringDate(this.con_sign_time_arr[0],this.con_sign_time_arr[1])
						}
						if(this.courseDetail.config_info.con_exam_time){
							var con_exam_time = this.courseDetail.config_info.con_exam_time.split(",")
							this.con_exam_time = con_exam_time[0] + " 至 " + con_exam_time[1]
						}
						setTimeout(function(){
							if(that.$refs.Intro_des.offsetHeight > 85){
								that.moreShow = true;
							}
						}, 500);
						this.lecturerList = this.courseDetail.lecturerData;
						// this.rateVal = Number(this.lecturerList.lec_praise);
						if(this.lecturerList.length){
							this.lecturerList.forEach((item,index) => {
								item.lec_praise = Number(item.lec_praise)
							})
							this.lecturerInfo = this.lecturerList[0]
							this.cuurlecturer = 0
							setTimeout(function(){ 
								if(that.$refs.lec_des.offsetHeight > 82){
									that.moreShowdes = true;
								}
							}, 500);
						}
						this.relevantCourse = this.courseDetail.relevantCourse;
						this.isOrderStatus = response.data.data.isOrderStatus;//是否在订单中
						this.isCarts = response.data.data.isCarts;//是否在购物车中
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击展开收起
			openOrHide(){
				this.isOpen = !this.isOpen;
			},
			//点击展开收起
			openOrHidedes(){
				this.isOpendes = !this.isOpendes;
			},
			//获取课程目录
			getCourseware(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/courseware.html", {
					course_id:this.$route.query.course_id,
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.mlList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击上一个
			lec_prev(){
				if(this.cuurlecturer == 0){
					this.$message({
						message: "已经是第一个了",
						type: 'warning',
						showClose: true
					});
				}else{
					this.isOpendes = false;
					this.moreShowdes = false;
					this.lecturerInfo = this.lecturerList[this.cuurlecturer - 1]
					this.cuurlecturer = this.cuurlecturer - 1 
					if(this.$refs.lec_des.offsetHeight > 82){
						this.moreShowdes = true;
					}
				}
			},
			//点击下一个
			lec_next(){
				if(this.cuurlecturer == (this.lecturerList.length - 1)){
					this.$message({
						message: "已经是最后一个了",
						type: 'warning',
						showClose: true
					});
				}else{
					this.isOpendes = false;
					this.moreShowdes = false;
					this.lecturerInfo = this.lecturerList[this.cuurlecturer + 1]
					this.cuurlecturer = this.cuurlecturer + 1
					if(this.$refs.lec_des.offsetHeight > 82){
						this.moreShowdes = true;
					}
				}
			},
			//获取课程笔记
			getNotes(page){
				debugger
				this.$http.post(this.PublicJs.publicPath + "/api/student.Notes/index.html", {
					cono_courseid:this.$route.query.course_id,
					token:window.sessionStorage.getItem('token'),
					limit:this.pageSize,
					page:page,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.NotesList = response.data.data.data;
						this.total = response.data.data.total
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击切换
			tabChange(type){
				this.MenuChose = type;
			},
			//笔记分页
			changPage(val){
				//获取课程笔记
				this.getNotes(val)
			},
			//立即学习
			learnNow(){
				if(window.sessionStorage.getItem('token')){
					var that = this;
					if(this.canSignUp){
						if(this.isOrderStatus == 0 || this.isOrderStatus == 1 || this.isOrderStatus == 2 || this.isOrderStatus == 5){
							this.$router.push({
								path: "/confirmOrder",
								query: {
									course_id:this.$route.query.course_id,
									isCarts:this.PublicJs.Encrypt(this.isCarts)
								}
							});
						}else{
							this.$message({
								message: "该课程已在订单中，请前往订单中支付",
								type: 'warning',
								showClose: true
							});
							setTimeout(function(){
								that.$router.push({
									path: "/order"
								});
							},1500);
						}					
					}else{
						var curDate = new Date(),
						beginDate = new Date(this.con_sign_time_arr[0]),
						endDate = new Date(this.con_sign_time_arr[1]);
						if(curDate < beginDate){
							this.$message({
								message: "该年度报名尚未开始",
								type: 'warning',
								showClose: true
							});
						}else if(curDate > endDate){
							this.$message({
								message: "该年度报名已结束，如有疑问请联系客服",
								type: 'warning',
								showClose: true
							});
						}
					}
				}else{
					this.$parent.Callloginpopup();
				}
			},
			//加入购物车
			addToCart(){
				if(window.sessionStorage.getItem('token')){
					if(this.canSignUp){
						this.loading=true;
						this.$http.post(this.PublicJs.publicPath + "/api/student.Carts/add.html", {
							course_id:this.$route.query.course_id,
							token:window.sessionStorage.getItem('token')
						}, {
							emulateJSON: true
						}).then(response => {
							this.loading=false;
							if(response.data.code == 1){
								this.$http.post(this.PublicJs.publicPath + "/api/student.Carts/index.html", {
									token:window.sessionStorage.getItem('token')
								}, {
									emulateJSON: true
								}).then(response => {
									if(response.data.code == 1){
										Bus.$emit('busCartNum', response.data.data.total);//购物车数量
									}else{
										this.$message({
											message: response.data.msg,
											type: 'warning',
											showClose: true
										});
									}
								}, response => {
									console.log(response)
								});
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							if(response.status == 401){
								var that = this;
								this.$message({
									message: "您的账号在别处登录，请重新登录",
									type: 'warning',
									showClose: true
								});
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								setTimeout(function(){
									that.$router.go(0)
								},2000); 
								
							}
						});
					}else{
						var curDate = new Date(),
						beginDate = new Date(this.con_sign_time_arr[0]),
						endDate = new Date(this.con_sign_time_arr[1]);
						if(curDate < beginDate){
							this.$message({
								message: "该年度报名尚未开始",
								type: 'warning',
								showClose: true
							});
						}else if(curDate > endDate){
							this.$message({
								message: "该年度报名已结束，如有疑问请联系客服",
								type: 'warning',
								showClose: true
							});
						}
					}
				}else{
					this.$parent.Callloginpopup();
				}
			},
			//继续学习
			gotoVideoPlay(){
				this.$router.push({
					path: "/videoPlay",
					query: {
						course_id:this.$route.query.course_id,
						source:this.PublicJs.Encrypt("courseInfo")
					}
				});
			},
			//跳转学习
			gotoVideoPlays(item){
				this.$router.push({
					path: "/videoPlay",
					query: {
						course_id:this.$route.query.course_id,
						source:this.PublicJs.Encrypt("courseInfo"),
						cuur_cow_id:this.PublicJs.Encrypt(item.cow_id),
					}
				});
			},
			//反转义
			html_decode(str){
				var s = ""; 
				if (str.length == 0) return ""; 
				s = str.replace(/&amp;/g, "&"); 
				s = s.replace(/&lt;/g, "<"); 
				s = s.replace(/&gt;/g, ">"); 
				s = s.replace(/&nbsp;/g, " "); 
				s = s.replace(/&#39;/g, "\'"); 
				s = s.replace(/&quot;/g, "\""); 
				s = s.replace(/<br\/>/g, "\n"); 
				return s; 
			},
			//相关课程
			toCourseInfo(item){
				this.$router.push({
					path: "/courseInfo",
					query: {
						course_id:this.PublicJs.Encrypt(item.cou_id)
					}
				});
			},
		},
		created() {
			if(this.$cookies.get("are_code")){
				this.are_code = this.$cookies.get("are_code")
			}
			//获取课程详情
			this.getCourseDetail();
			//获取课程目录
			this.getCourseware();
			if(window.sessionStorage.getItem('token')){
				//获取课程笔记
				this.getNotes(1)
			}
		},
		watch: {
			'$route'(to, from) {
				if (to.query) {
					//获取课程详情
					this.getCourseDetail();
					//获取课程目录
					this.getCourseware();
					//获取课程笔记
					this.getNotes(1)
				}
			}
		},
	}
</script>

<style scoped="scoped">
	.info_Header{
		height: 432px;
		width: 100%;
		background-color: #FFF;
		box-shadow: 0px 1px 6px 0px rgba(153,153,153,0.18); 
		border-top: 1px solid #F9F9F9;
	}
	.info_H_Con{
		width: 1200px;
		margin: 0 auto;
		padding-top: 34px;
	}
	.crumbs {
	    font-size: 14px;
	}
	.cu{
		cursor: pointer;
	}
	.current {
	    vertical-align: top;
	    line-height: 1;
	}
	.el-breadcrumb {
	    display: inline-block;
	    vertical-align: top;
	}
	/* 头部 */
	.Header_Details{
		position: relative;
		padding-left: 565px;
		width: 1200px;
		margin: 0 auto;
		margin-top: 30px;
	}
	.coursesImg{
		position: absolute;
		top: 0;
		left: 0;
		width: 524px;
		height: 314px;
	}
	.Header_Right{
		position: relative;
		width: 100%;
		height: 314px;
	}
	.Header_Tit{
		font-size: 20px;
		color: #333;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		margin-bottom: 27px;
	}
	.Header_TY{
		margin-bottom: 23px;
	}
	.Header_Type{
		width: 46px;
		height: 24px;
		background: #ffac3d;
		border-radius: 4px;
		font-size: 12px;
		color: #FFF;
		line-height: 24px;
		text-align: center;
		display: inline-block;
		margin-right: 10px;
	}
	.Header_Year{
		color: #999;
		font-size: 16px;
	}
	.Header_rate{
		display: inline-block;
		vertical-align: top;
	}
	.Header_duration{
		font-size: 14px;
		color: #999;
		vertical-align: middle;
	}
	.Header_DA{
		line-height: 30px;
		font-size: 0;
		margin-bottom: 15px;
	}
	.Header_Audition{
		display: inline-block;
		vertical-align: middle;
		font-size: 0;
		line-height: 30px;
		cursor: pointer;
		margin-left: 30px;
	}
	.Header_Audition img{
		vertical-align: middle;
		margin-right: 10px;
	}
	.Header_Audition span{
		vertical-align: middle;
		font-size: 14px;
		color: #333;
	}
	.Header_sign,.Header_train{
		font-size: 0;
		line-height: 30px;
	}
	.Header_sign img,.Header_train img{
		vertical-align: middle;
		margin-right: 8px;
	}
	.Header_sign span,.Header_train span{
		font-size: 14px;
		color: #999;
		vertical-align: middle;
	}
	.Header_sign{
		margin-bottom: 11px;
	}
	.Header_train{
		margin-bottom: 50px;
	}
	.Header_Price{
		font-size: 20px;
		color: #ff483b;
	}
	.Header_Price span{
		font-size: 40px;
	}
	.Header_Learn,.Header_tocart,.Header_Keep{
		width: 120px;
		height: 44px;
		border-radius: 22px;
		text-align: center;
		line-height: 44px;
		font-size: 14px;
		font-weight: bold;
		position: absolute;
		bottom:0;
		cursor: pointer;
	}
	.Header_Learn{
		background: #ff483b;
		color: #FFF;
		right: 140px;
	}
	.Header_tocart{
		background: #fee7e7;
		color: #ff483b;
		right: 0;
	}
	.Header_Learn.ash,
	.Header_tocart.ash{
		background: #C0C0C0;
		color: #FFF;
	}
	.Header_Learn:hover,
	.Header_tocart:hover{
		opacity: 0.8;
	}
	.Header_Keep{
		right: 0;
		color: #FFF;
		background: linear-gradient(0deg,rgba(77,110,242,0.90) 0%, rgba(77,110,242,0.70) 100%);
	}
	.Header_Keep:hover{
		background: linear-gradient(0deg, rgba(77,110,242,0.70) 100%,rgba(77,110,242,0.90) 0%);
	}
	.Header_Learned{
		font-size: 14px;
		color: #ff0000;
		margin-right: 40px;
	}
	.Header_Hour{
		font-size: 14px;
		color: #333;
	}
	/* 内容 */
	.courseCon{
		width: 1200px;
		margin: 0 auto;
		padding-top: 20px;
		padding-bottom: 47px;
	}
	.courseCon::after{
		content: "";
		display: block;
		clear: both;
	}
	.courseCon_L{
		width: 850px;
		float: left;
	}
	.courseCon_R{
		width: 328px;
		float: right;
	}
	.courseIntro{
		width: 100%;
		box-shadow: 0px 4px 7px 0px rgba(51,51,51,0.13); 
		background-color: #FFF;
		padding: 15px 30px;
	}
	.Intro_tit{
		height: 28px;
		background-image: url(../images/tit_icon.png);
		background-repeat: no-repeat;
		background-position: center left;
		line-height: 28px;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		padding-left: 16px;
		position: relative;
	}
	.Intro_des{
		font-size: 14px;
		color: #333;
		line-height: 24px;
		padding-top: 10px;
		overflow: hidden;
	}
	.moreIndustry{
		/* display: inline-block;
		position: absolute;
		right: 0;
		top: 0; */
		cursor: pointer;
		font-size: 14px;
		font-weight: initial;
		color: #999;
		text-align: center;
		padding-top: 5px;
	}
	.moreIndustry i{
		margin-left: 5px;
	}
	.courseMenu{
		width: 100%;
		box-shadow: 0px 4px 7px 0px rgba(51,51,51,0.13); 
		background-color: #FFF;
		margin-top: 20px;
	}
	.courseMenu_tit{
		height: 60px;
		border-bottom: 2px solid #EEEEEE;
	}
	.courseMenu_tit span{
		display: inline-block;
		width: 130px;
		text-align: center;
		line-height: 57px;
		font-size: 16px;
		color: #333;
		cursor: pointer;
	}
	.courseMenu_tit span.only{
		font-weight: bold;
	}
	.courseMenu_tit span.active{
		font-weight: bold;
		border-bottom: 2px solid #5E7BF2;
	}
	.mlList{
		padding: 0 34px;
	}
	.mlItem{
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #F9F9F9;
		line-height: 64px;
		position: relative;
		font-size: 16px;
		cursor: pointer;
		padding-right: 110px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
	}
	.mlItem:last-child{
		border-bottom: none;
	}
	.mlItem_no{
		color: #999999;
	}
	.mlItem_tit{
		color: #333;
	}
	.mlItem_xs{
		color: #999999;
		position: absolute;
		right: 0;
		top: 0;
	}
	.mlItem_xs span{
		vertical-align: middle;
	}
	.mlItem_xs i{
		vertical-align: middle;
		font-size: 24px;
		margin-left: 5px;
	}
	.mlItem:hover .mlItem_tit{
		color: #4D6EF2;
	}
	.mlItem:hover .mlItem_no,
	.mlItem:hover .mlItem_xs{
		color: #7490FF;
	}
	.lecturerInfo{
		width: 100%;
		box-shadow: 0px 5px 7px 0px rgba(77,110,241,0.17); 
		position: relative;
		margin-bottom: 20px;
		background-color: #FFF;
		padding-bottom: 10px;
		position: relative;
	}
	.lecturerPicture{
		width: 102px;
		height: 102px;
		opacity: 1;
		border: 2px solid #ffffff;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		margin-left: -51px;
		top: 104px;
	}
	.lec_name{
		font-size: 18px;
		color: #333;
		text-align: center;
		padding-top: 68px;
		line-height: 18px;
		padding-bottom: 26px;
	}
	.lec_evaluate{
		width: 100%;
		padding-bottom: 30px;
	}
	.lec_evaluate::after{
		content: "";
		display: block;
		clear: both;
	}
	.evaluateItem{
		width: 50%;
		text-align: center;
		float: left;
	}
	.evaluate_num{
		font-size: 14px;
		color: #999;
	}
	.evaluate_text{
		font-size: 14px;
		color: #333;
		line-height: 32px;
	}
	.lec_des{
		width: 280px;
		padding-top: 10px;
		margin: 0 auto;
		border-top: 1px solid #EEEEEE;
		overflow: hidden;
		font-size: 14px;
		color: #333;
		line-height: 24px;
	}
	.morelec_des{
		/* position: absolute;
		top: 367px;
		right: 7px; */
		font-size: 14px;
		cursor: pointer;
		text-align: center;
	}
	.lec_prev,
	.lec_next{
		position: absolute;
		top: 186px;
		font-size: 30px;
		color: #999;
		cursor: pointer;
	}
	.lec_prev{
		left: 10px;
	}
	.lec_next{
		right: 10px;
	}
	.lec_prev:hover,
	.lec_next:hover{
		color: #4D6EF2;
	}
	.relevantCourse{
		width: 100%;
		background-color: #FFF;
		border-radius: 2px;
		box-shadow: 0px 3px 7px 0px rgba(51,51,51,0.17); 
	}
	.relevant_tit{
		text-align: center;
		line-height: 64px;
		font-size: 16px;
		color: #333;
		font-weight: bold;
	}
	.relevantList{
		padding: 0 16px 27px 16px;
	}
	.relevantItem{
		width: 100%;
		height: 96px;
		margin-bottom: 20px;
		padding-left: 170px;
		position: relative;
		cursor: pointer;
	}
	.relevantItem:last-child{
		margin-bottom: 0;
	}
	.relevant_img{
		width: 160px;
		height: 96px;
		border-radius: 4px;
		position: absolute;
		top: 0;
		left: 0;
	}
	.relevant_Name{
		font-size: 14px;
		color: #333;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		line-height: 24px;
		height: 48px;
	}
	.relevant_Year{
		font-size: 14px;
		color: #999;
		margin-top: 20px;
	}
	.NotesList{
		padding: 0 33px;
	}
	.NotesItem{
		border-bottom: 1px solid #EEEEEE;
		padding: 26px 0;
	}
	.NotesItem:last-child{
		border-bottom: none;
	}
	.NotesItem_top{
		height: 46px;
		position: relative;
		padding-left: 62px;
	}
	.headimg{
		width: 46px;
		height: 46px;
		position: absolute;
		top: 0;
		left: 0;
	}
	.NotesItem_name{
		font-size: 14px;
		color: #333;
		line-height: 23px;
	}
	.NotesItem_TW{
		font-size: 14px;
		color: #999;
		line-height: 23px;
	}
	.NotesItem_con{
		padding-left: 62px;
		font-size: 14px;
		color: #333;
		line-height: 24px;
		padding-top: 10px;
	}
	.NotesPaginat{
		padding: 20px 0;
		text-align: center;
	}
	.NotesItem_time{
		margin-right: 15px;
	}
</style>
